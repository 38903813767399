<template>
  <div class="otp">
    <div class="">
      <p class="text-center font-size-22 text-black font-weight-bold">
        {{ $t("auth.otbTitle") }}
      </p>
      <p class="text-center font-size-22 text-black font-weight-bold">
        {{ $t("auth.otbInfo") }}
      </p>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="otbForm">
      <b-form
        class="position-relative form-top"
        @submit.prevent="handleSubmit(submit())"
      >
        <div class="otp__wrapper" ref="otp">
          <input
            class="otp__input"
            v-for="(numb, idx) in otp"
            :key="idx"
            v-model="otp[idx]"
            maxlength="1"
            @input="handleInput($event, idx)"
          />
        </div>

        <app-button class="mt-4" :loading="loadingChecking">
          {{ $t("auth.continueOtb") }}
        </app-button>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { core } from "@/config/pluginInit";

import appButton from "../../../components/buttons/appButton.vue";
import authService from "../services/auth.js";
import EventBus from "../../../eventBus";
export default {
  components: { appButton },
  name: "otb",
  props: ["email"],
  data() {
    return {
      numberOfOtp: 6,
      otp: [],
      loadingChecking: false,
    };
  },
  computed: {
    authData() {
      return JSON.parse(localStorage.getItem("authData"));
    },
  },
  methods: {
    createArray() {
      for (let i = 0; i < this.numberOfOtp; i++) {
        this.otp.push("");
      }
    },
    handleInput(e, idx) {
      if (e.target.value.length === 1) {
        if (idx < this.numberOfOtp - 1) {
          this.$refs.otp.children[idx + 1].focus();
        } else {
          this.$refs.otp.children[idx].blur();
          this.submit();
        }
      }
    },

    async submit() {
      this.loadingChecking = true;
      try {
        const { data } = await authService.verifyAccount({
          ...this.authData,
          otp: this.otp.join(""),
        });

        this.$store.dispatch("setUserInfo", {
          user: data.data.customer,
          token: data.meta.token,
        });
        this.$store.dispatch("setUserToken", JSON.stringify(data.meta.token));
        this.$router.push("/");
        localStorage.removeItem("authData");
        this.$store.dispatch("cart/getCart");
        EventBus.$emit("auth", data.data.customer);
      } finally {
        this.loadingChecking = false;
      }
    },
  },
  created() {
    this.createArray();
  },
  mounted() {
    this.$refs.otp.children[0].focus();
  },
};
</script>

<style lang="scss" scoped>
.otp {
  max-width: 500px;
  margin: 0 auto;
  &__wrapper {
    display: flex;
    gap: 10px;
    justify-content: center;
    direction: ltr;
  }
  &__input {
    width: 100%;
    max-width: 50px;
    height: 60px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    text-align: center;
    font-size: 20px !important;
    font-weight: bold;
    color: var(--iq-primary);
    transition: all 0.3s ease;
    &:focus {
      outline: var(--iq-primary) auto 1px !important;
    }
  }
}
</style>
